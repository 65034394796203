/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { axios } from 'axios';
import moment from 'moment';
import PNotify from 'pnotify/dist/es/PNotify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ADMIN_ROLES, NON_ADMIN_ROLES } from 'constants/roles';
import { base_url } from 'env';

import { store } from '../store';

const MySwal = withReactContent(Swal);

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

export const getAdminToken = () => {
  const adminToken = localStorage.getItem('admin-token');
  return adminToken;
};

export const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  AdminToken: getAdminToken() ? `Bearer ${getAdminToken()}` : null,
});

export const checkRole = (allowedRoles = [], notAllowedList = []) => {
  const user = store.getState().login?.user;
  const roles = user.role_types.client;
  let isAllowed = false;

  if (allowedRoles.length > 0) {
    if (roles.length > 0) {
      isAllowed = !!roles.find((role) => allowedRoles.includes(role));
    }
    return isAllowed;
  }

  if (notAllowedList && notAllowedList.length > 0) {
    if (roles.length > 0) {
      isAllowed = !!roles.find((e) => !notAllowedList.includes(e));
    }
    return isAllowed;
  }

  // TODO update this everywhere
  if (store.getState().login.user.role_types.client) {
    const isAdmin = store
      .getState()
      .login.user.role_types.client.find((role) => ADMIN_ROLES.includes(role));

    const isNonAdmin = store
      .getState()
      .login.user.role_types.client.find((role) =>
        NON_ADMIN_ROLES.includes(role),
      );

    if (isAdmin) {
      return true;
    }

    if (isNonAdmin) {
      return false;
    }
  }

  return false;
};

export const isCSR = () => {
  if (store.getState().login.user.role_types.client) {
    const isNonAdmin = store
      .getState()
      .login.user.role_types.client.find((e) => ['csr', 'sales'].includes(e));
    return isNonAdmin;
  }

  return null;
};

export const isWhAdmin = () => {
  return store.getState().login?.adminFlag ?? false;
};

export const isSales = () => {
  if (store.getState().login.user.role_types.client) {
    const isSalesRep = store
      .getState()
      .login.user.role_types.client.find((e) => ['sales'].includes(e));
    return isSalesRep;
  }

  return null;
};

export const checkIfSpecificClient = () => {
  switch (
    store.getState().login.user.role_types.client &&
    store.getState().login.user.client_id
  ) {
    case 169:
    case 172:
    case 210:
    case 273:
    case 278:
    case 340:
    case null:
      return true;
    default:
      return false;
  }
};

export const checkIfClientIsAuthorized = (clientIds) => {
  const currentClientId = store.getState().login.user.client_id;

  return clientIds.includes(currentClientId);
};

export const userData = () => ({
  client_id: store.getState().login.user.client_id,
  role_id: store.getState().login.user.role_id,
  user_id: store.getState().login.user.id,
});

export const fileUploadData = () => {
  const dealId = JSON.parse(localStorage.getItem('deal_id'));

  return {
    client_id: store.getState().login.user.client_id,
    role_id: store.getState().login.user.role_id,
    user_id: store.getState().login.user.id,
    deal_id: dealId,
  };
};

export const API = () => {
  axios.create({
    base_url,
    timeout: 1000,
    headers: { Authorization: `Bearer ${getToken()}` },
  });
};

export const params = () => {
  return {
    client_id: store.getState().login.user.client_id,
    role_id: store.getState().login.user.role_id,
    user_id: store.getState().login.user.id,
  };
};

export const utcToTimezone = (utcDateTime, timeZone = null) => {
  return timeZone
    ? moment.utc(utcDateTime).tz(timeZone)
    : moment.utc(utcDateTime).utcOffset(moment().format('Z'));
};

export const timeZoneToUtc = (dateTime) => {
  return moment.utc(dateTime);
};

const swalError = (type, message, title = null) => {
  return MySwal.fire({
    title: title || (type === 'success' ? 'Success' : 'Error'),
    html: message,
    icon: type,
  });
};

const PNotifyError = (type, message, title = null) => {
  switch (type) {
    case 'success':
      return PNotify.success({
        title: title || 'Success',
        text: message,
      });
    case 'error':
      return PNotify.error({
        title: title || 'Error',
        text: message,
      });
    case 'notice':
      return PNotify.notice({
        title: message || 'Notice',
        icon: 'fas fa-spinner fa-pulse',
      });
    default:
      break;
  }

  return null;
};

// Error from whole object from catch of axios call
export const parseAndShowMessage = (
  type,
  data,
  title = null,
  pNotify = false,
) => {
  let message = 'Undefined';
  switch (type) {
    case 'error':
      message = 'Something went wrong';
      if (data?.request) {
        if (data?.request?.responseText[0] !== '<') {
          message = JSON.parse(data?.request?.responseText)?.message;
        } else if (data?.request?.status === 404) {
          message = 'Not Found';
        }
      }
      break;

    case 'success':
      message = 'Success';
      if (data) {
        if (data.statusText) {
          message = data.statusText;
        } else if (data.data && typeof data.data === 'string') {
          message = data.data;
        }
      }
      break;

    case 'notice':
      break;
    default:
      break;
  }

  if (typeof data === 'string') {
    message = data;
  }

  if (pNotify) {
    return PNotifyError(type, message, title);
  }

  return swalError(type, message, title);
};
