export const TYPE_CLIENT = 'client';
export const TYPE_WH = 'wh';

export const ROLE_SUPER_ADMIN = 'super_admin';
export const ROLE_ADMIN = 'admin';
export const ROLE_CSR = 'csr'; // Customer Sales Representative
export const ROLE_TECHNICIAN = 'technician';
export const ROLE_ACCOUNTING = 'accounting';
export const ROLE_SALES = 'sales';
export const ROLE_CSO = 'cso'; // Central Station Operator
export const ROLE_TECH_MANAGER = 'tech_manager';

export const ROLES = [
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_CSR,
  ROLE_TECHNICIAN,
  ROLE_ACCOUNTING,
  ROLE_SALES,
  ROLE_CSO,
  ROLE_TECH_MANAGER,
];

export const ADMIN_ROLES = [ROLE_ACCOUNTING, ROLE_ADMIN, ROLE_SUPER_ADMIN];

export const NON_ADMIN_ROLES = [
  ROLE_CSR,
  ROLE_TECHNICIAN,
  ROLE_SALES,
  ROLE_TECH_MANAGER,
  ROLE_CSO,
];
