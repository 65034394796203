import moment from 'moment';
import numeral from 'numeral';
import history from '../store/history';

import { lookupTable } from './urlLookupTable'
import Geocode from 'react-geocode';


export function phoneNoFormat(phone) {
  //normalize string and remove all unnecessary characters
  if (phone) {
    phone = phone.toString().replace(/[^\d]/g, '');
    // (941) 662-4444

    //check if number length equals to 10
    if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    }else{
      return phone
    }
  }

  return ' ';
}

export function formatCurrency(num = null, decimals = 2) {
  return numeral(num).format(`$0,0.${'0'.repeat(decimals)}`)
}
export const standardizeDateFormat = (dateInput) => {
  return moment(dateInput).format('LL');
};

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const checkEmail = async (modelName, email) => {
  // const data = {
  //   email
  // };
  // await window.axios
  //   .post(`${modelName}/checkEmail`, data)
  //   .then()
  //   .catch(error => {
  //     parseAndShowMessage('error', error, 'Email Check');
  //   });
};

export const macAddFormat = addr => {
  return new Array(6)
    .join('00') // '000000000000'
    .match(/../g) // [ '00', '00', '00', '00', '00', '00' ]
    .concat(
      addr
        .toString(16) // "4a8926c44578"
        .match(/.{1,2}/g) // ["4a", "89", "26", "c4", "45", "78"]
    ) // ["00", "00", "00", "00", "00", "00", "4a", "89", "26", "c4", "45", "78"]
    .reverse() // ["78", "45", "c4", "26", "89", "4a", "00", "00", "00", "00", "00", "00", ]
    .slice(0, 6) // ["78", "45", "c4", "26", "89", "4a" ]
    .join(':'); // "78:45:c4:26:89:4a"
};

export const fetchTaxes = async zip => {
  let response;
  if (zip) {
    await window.axios
      .get(`tax/${zip}`)
      .then(res => (response = res.data.estimated_combined_rate))
      .catch(err => console.log(err));
  }
  return response;
};

export const getTaxPreference = async (model, taxType) => {
  let response;

  await window.axios
    .get(`setting/${model}/${taxType}`)
    .then(res => (response = res))
    .catch(err => console.log(err));

  return response;
};

export const subscriptionCalc = {
  dateMath: (start, next) => {
    const startDate = moment(start);
    const nextDate = moment(next);

    const daysInStartMonth = startDate.daysInMonth();
    const daysInNextMonth = nextDate.daysInMonth();
    const years = nextDate.diff(startDate, 'year');
    startDate.add(years, 'years');

    const months = nextDate.diff(startDate, 'months');
    startDate.add(months, 'months');

    const days = nextDate.diff(startDate, 'days');

    return {
      years,
      months,
      days,
      billDaysInStart: moment(startDate)
        .endOf('month')
        .diff(startDate, 'days'),
      billDaysInNext: moment(nextDate).diff(
        moment(nextDate).startOf('month'),
        'days'
      ),
      daysInStartMonth,
      daysInNextMonth
    };
  },
  calcProrated: (
    dateObj,
    products,
    tax,
    interval,
    prorated_amount = 0,
    prorated_tax_amount = 0
  ) => {
    const {
      years,
      months,
      days,
      daysInNextMonth
    } = dateObj;

    let priceWTax = 0;
    let priceWOTax = 0;
    let taxAmt = 0;
    let totalPrice = 0;

    products.map(p => {
      let price = (parseFloat(p.price) *
        (parseInt((p.quantity || p.qty) - parseInt(p.comp))
          / parseInt(interval)));

      if (p.is_taxable) {
        priceWTax += price * parseFloat(tax) + price;
        taxAmt += price * parseFloat(tax);
      } else {
        priceWOTax += price;
      }
      totalPrice = priceWTax + priceWOTax;
    });

    const dailyCost = totalPrice / daysInNextMonth;
    const dailyCostTax = taxAmt / daysInNextMonth;

    const monthDurationCost = totalPrice * (months > 0 ? months : 0);
    const monthDurationCostTax = taxAmt * (months > 0 ? months : 0);

    const yearTotal = 12 * (years > 0 ? years : 0) * totalPrice;
    const yearTotalTax = 12 * (years > 0 ? years : 0) * taxAmt;

    const totalDaysBillAmount = dailyCost * (days > 0 ? days : 0);
    const totalDaysBillAmountTax = dailyCostTax * (days > 0 ? days : 0);

    const taxTotal =
      (years > 0 ? years : 0) == 0 &&
        (months > 0 ? months : 0) == 0 &&
        (days > 0 ? days : 0) == 0
        ? 0 + prorated_tax_amount / 100
        : totalDaysBillAmountTax +
        monthDurationCostTax +
        yearTotalTax +
        prorated_tax_amount / 100;

    const subTotal =
      (years > 0 ? years : 0) == 0 &&
        (months > 0 ? months : 0) == 0 &&
        (days > 0 ? days : 0) == 0
        ? 0 + prorated_amount / 100
        : totalDaysBillAmount +
        monthDurationCost +
        yearTotal -
        taxTotal +
        prorated_amount / 100;

    const total = subTotal + taxTotal;

    return {
      taxTotal,
      subTotal,
      total,
      monthDurationCost
    };
  },
  calcNextBilling: (dateObj, products, tax , interval) => {
    let priceWTax = 0;
    let priceWOTax = 0;
    let taxAmt = 0;
    let totalPrice = 0;


      products.map(p => {
      let price =
        parseFloat(p.price) *
        ((parseInt(p.quantity || p.qty) - parseInt(p.comp)) / interval);
      if (p.is_taxable || (p.product && p.product.is_taxable)) {
        priceWTax += price * parseFloat(tax) + price;
        taxAmt += price * parseFloat(tax);
      } else {
        priceWOTax += price;
      }
      totalPrice = price;
    });
    const taxTotal = taxAmt * interval;
    const subTotal = (priceWTax + priceWOTax - taxAmt) * interval;
    const total = subTotal + taxTotal;

    return {
      taxTotal,
      subTotal,
      total
    };
  }
};

export const calcAvailCredit = (limit, charges) => {
  let credit = 0;
  credit = limit - parseFloat(charges);
  return credit / 100;
};

const removeSpecChars = value => {
  return value.replace(/[^a-zA-Z]/g, '');
};


export const pageHandler = () => {

  if (lookupTable[history.location.pathname]) {
    return lookupTable[history.location.pathname]
  }
}

// return {
//       days: diffDuration.days(),
//       months: diffDuration.months(),
//       years: diffDuration.years(),
//       billDaysInStart: moment(startDate)
//         .endOf('month')
//         .diff(startDate, 'days'),
//       billDaysInNext: moment(nextDate).diff(
//         moment(nextDate).startOf('month'),
//         'days'
//       ),
//       z: { years, months, days }
//     };

function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

export function dollarsToPennies(value) {
  if (value !== 0 && value !== '' && value !== null && value !== undefined) {
    return Math.round(value * 100);
  }

  return value;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const validatePassword = password => {
  if (password.length > 0 && password.length < 8) {
    return '* Password must be 8-16 characters long';
  }
  if (password.length > 0 && !password.match(/[0-9]/g)) {
    return '* Password must contain at least one number';
  }
  if (password.length > 0 && !password.match(/[A-Z]/g)) {
    return '* Password must contain at least one uppercase letter';
  }
  if (password.length > 0 && !password.match(/[a-z]/g)) {
    return '* Password must contain at least one lowercase letter';
  }
  if (password.length > 0 && !password.match(/[!@#$%^&*]/g)) {
    return '* Password must contain at least one special character';
  }
  return null;
};

export const isObjectEmpty = (object) => {
  return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
};

export const keyExistsInObject = (object, key) => {
  return object.hasOwnProperty(key);
};

export function roundToTwoDecimalPlacesAndFormatToUs(number) {
  return (Math.round(number * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
}

export function convertMMYYToMonthYear(inputString, justNumber = false) {
  try {
    let month, year
    if (justNumber) {
      [month, year] = inputString.split('/');
    } else {
      [month, year] = inputString.split(' / ');
    }
    // const [month, year] = inputString.split(' / ');
    const numericMonth = parseInt(month, 10);
    let numericYear = parseInt(year, 10);

    // Convert two-digit year to four-digit year (assuming the year is between 2000 and 2099)
    if (numericYear < 100) {
      numericYear += 2000;
    }

    const stringMonth = numericMonth.toString().padStart(2, '0');
    const stringYear = numericYear.toString();

    return [stringMonth, stringYear];
  } catch (error) {
    throw new Error("Invalid input format. Please use 'mm / yy' format.");
  }
};

export const formatExpirationDate = (value) => {
  const formattedValue = value
    .replace(/\D/g, '') // Remove non-digit characters
    .slice(0, 6) // Limit the length to 6 characters (MMYYYY)
    .replace(/(\d{2})(\d{0,4})/, '$1 / $2'); // Add a space between MM and YYYY
  return formattedValue;
};

export function expires_date_format(string) {
  return string.replace(
    /[^0-9]/g, '' // To allow only numbers
  ).replace(
    /^([2-9])$/g, '0$1' // To handle 3 > 03
  ).replace(
    /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
  ).replace(
    /^0{1,}/g, '0' // To handle 00 > 0
  ).replace(
    /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
  );
};

export function formatCardNumber(input) {
  // Remove all non-numeric characters from the input string
  const cleanedInput = input.replace(/\D/g, '');

  // Use a regular expression to add spaces after every four digits
  const formattedInput = cleanedInput.replace(/(\d{4})(?=\d)/g, '$1 ');

  return formattedInput;
}

export const getLatLngFromAddress = (coordinates) => {
  if (!!coordinates) return false
  const fullAddress = `${coordinates.address}, ${coordinates.city}, ${coordinates.state} ${coordinates.zip}, US`;
  try {
    const response = Geocode.fromAddress(fullAddress);
    const { lat, lng } = response.results[0]?.geometry?.location ?? { lat: '', lng: '' };
    return { lat, lng };
  } catch (error) {
    console.log('error', error)
    return { lat: '', lng: '' };
  }
}

/**
 * Formats phone number including invalid to match this format (999) 999-9999
 * @param phone
 * @returns {string}
 */
export const formatPhoneNumber = (phone) => {
  phone = String(phone);

  const digits = phone.replace(/\D/g, '');

  // Step 2: Extract the parts of the phone number
  const areaCode = digits.slice(0, 3);
  const firstPart = digits.slice(3, 6);
  const secondPart = digits.slice(6, 10);

  let result = '';

  if (areaCode) {
    result += `(${areaCode})`;
  }

  if (firstPart) {
    result += ` ${firstPart}-`;
  }

  if (secondPart) {
    result += secondPart;
  }

  return result;
};

export const makeProductOptionName = (product) => {

  if (!product) {
    return '';
  }

  let result = [];

  if (product.manufacturer_sku) {
    result.push(product.manufacturer_sku);
  }

  if (product.manufacturer) {
    result.push(product.manufacturer);
  }

  const mainName = product.label || product.display_name || '';

  if (mainName) {
    result.push(mainName);
  }

  return result.join(' - ');
};
